import {mapActions, mapGetters} from "vuex";
import CustomBreadcrumbs from '@/components/custom-breadcrumbs/index.vue';
export default {
  name:'delivery-ua',
  data() {
    return {}
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  components: {
    CustomBreadcrumbs
  },
  computed: {
    ...mapGetters({
      deliveryTypes: 'order/deliveryTypes'
    })
  },
  methods: {
    ...mapActions({
      getDeliveryTypes: 'order/GET_DELIVERY_TYPES'
    })
  },
  created() {
    this.getDeliveryTypes()
  }
}
